div.container
{
  position: relative;
  bottom: 17rem;
   height: 2.5rem;
   text-align: center;
	padding: 10em 3em;
  font-size: 2rem;
}

div.container a
{
    color: #000;
    text-decoration: none;
    font-family: 'VT323', monospace;
    margin: 0 10px;
    padding: 10px 10px;
    z-index: 0;
    cursor: pointer;
}


div.brackets a:before, div.brackets a:after
{
    opacity: 0;
    font-size: 45px;
    top: 0px;
    transition: all 0.3s;
}

div.brackets a:before
{
    content: '<';
    transform: translateX(10px);
}

div.brackets a:after
{
    content: '>';
    transform: translateX(-10px);
}

div.brackets a:hover:before, div.brackets a:hover:after
{
    opacity: 1;
    transform: translateX(0px);
}
