.title {
  width: 350px;
  height: 100px;
  text-align: center;
  font-family: 'Share Tech Mono', monospace;
  font-size: 20px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;

}

.work-title{
  font-size: 25px;
  font-family: 'Share Tech Mono', monospace;
}


.main-title {
  width: 350px;
  height: 100px;
  text-align: center;
  font-family: 'Share Tech Mono', monospace;
  font-size: 20px;
  position: absolute;
  bottom: 20rem;
  left: 0;
  right: 0;
  margin: auto;

}


.logo {
  height: 10rem;
  position: absolute;
}
.headshot{
  position: absolute;
  bottom: 100px;
  left: 300px;
}
p{
  font-family: 'Lato', sans-serif;
  text-align: center;
}
a{
    font-family: 'Lato', sans-serif;
  }
.blog-page{
  font-family: 'Alegreya Sans SC', sans-serif;
  font-size: 20px;
  line-height: 50px;
  width: 750px;
  height: 400px;
  position: absolute;
  top: 5rem;
  right: 2rem;
}

.work-page{
  font-family: 'Alegreya Sans SC', sans-serif;
  font-size: 20px;
  width: 750px;
  height: 400px;
  top: 5rem;
  right: 2rem;
}



.about-page{
  font-family: 'Alegreya Sans SC', sans-serif;
  font-size: 20px;
  line-height: 50px;
  width: 750px;
  height: 400px;
  top: 5rem;
  right: 2rem;
}
